import {Directive, Self, Optional} from '@angular/core';
import {MatTab} from '@angular/material/tabs';
import {BehaviorSubject} from 'rxjs';

@Directive({
    selector: '[mposFormSection]'
})
export class MposFormSectionDirective {
    private _sectionErrorStateSubject = new BehaviorSubject<boolean>(false);
    public readonly formSectionErrorState$ = this._sectionErrorStateSubject.asObservable();

    constructor(@Self() @Optional() private matTab: MatTab) {}
}
