import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'allOrSelectedFilter'
})
export class MposAllOrSelectedFilterPipe implements PipeTransform {
    transform(all: any[], selected: any[]): any {
        if (selected.length === 0) {
            return all;
        }
        return all.filter((item) => selected.filter((selectedItem) => selectedItem.id === item.id).length);
    }
}
