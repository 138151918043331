import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    forwardRef,
    HostBinding,
    Inject,
    Input,
    OnInit,
    Optional,
    Output
} from '@angular/core';
import {FormGroupDirective} from '@angular/forms';
import {faInfoCircle} from '@fortawesome/free-solid-svg-icons';
import {TranslateService} from '@ngx-translate/core';
import {TRANSLATION_BASE} from 'app/constants/injection-tokens';
import {Observable} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

import {MposFormDirective} from 'app/common/mpos-forms/directives/mpos-form.directive';
import {MposFormElement} from 'app/common/mpos-forms/shared/mpos-form-element';
import {ViewChild, HostListener} from '@angular/core';
import {NzSelectComponent} from 'ng-zorro-antd/select';
import {LogService} from 'app/blocks/service/log.service';
import {debounce} from 'lodash';
import {Store} from '@ngxs/store';
import {RoleService} from 'app/blocks/service/api/role.service';

export const formElementProvider: any = {
    provide: MposFormElement,
    useExisting: forwardRef(() => MposSelectComponent)
};

@Component({
    selector: 'mpos-select',
    template: `
        <nz-form-item [formGroup]="thisFormGroup" [ngStyle]="{'justify-content': getPosition()}" *ngIf="this.showField">
            <div [ngStyle]="{width: width}">
                <nz-form-label *ngIf="!suppressLabel" [nzFor]="fieldLabel$ | async" [nzRequired]="fieldIsRequired" [nzNoColon]="true">
                    <span>
                        {{ fieldLabel$ | async }}
                        <fa-icon *ngIf="fieldHint$ | async as hint" [icon]="faInfoIcon" size="xs" nz-tooltip [nzTooltipTitle]="hint"></fa-icon>
                    </span>
                </nz-form-label>
                <nz-form-control [nzErrorTip]="suppressError ? null : (validationError$ | async)">
                    <nz-input-group [ngStyle]="{width: width, 'max-width': width, display: 'flex', flexDirection: 'row', alignItems: 'center'}">
                        <nz-select
                            #selectElement
                            id="{{ 'mpos-select-' + field + idSuffix }}"
                            [nzDisabled]="!!readonly"
                            [ngStyle]="{
                                width: '100%',
                                color: color,
                                overflow: 'hidden',
                                'text-overflow': 'ellipsis',
                                'white-space': 'nowrap'
                            }"
                            [nzMaxTagCount]="maxDisplayed"
                            [nzTokenSeparators]="[',']"
                            [nzAutoFocus]="focus"
                            [nzMode]="getMode()"
                            formControlName="{{ field }}"
                            [compareWith]="compareFn"
                            [nzAllowClear]="allowClear"
                            (ngModelChange)="onChange($event)"
                            (nzOpenChange)="registerOpen($event)"
                            [nzPlaceHolder]="placeholder">
                            <nz-option
                                *ngFor="let option of options$ | async"
                                [nzValue]="getValue(option)"
                                [nzLabel]="getLabel(option)"
                                [nzDisabled]="disableOption(option)">
                            </nz-option>
                        </nz-select>
                    </nz-input-group>
                </nz-form-control>
            </div>
        </nz-form-item>
    `,
    styleUrls: ['./mpos-select.component.scss'],
    providers: [formElementProvider],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class MposSelectComponent extends MposFormElement implements OnInit {
    @ViewChild(NzSelectComponent, {static: true})
    nzSelectElement: NzSelectComponent;

    @Input() @HostBinding('class.flex-grow') grow = true;
    @Input() @HostBinding('style.width') outerWidth: string;
    @Input() @HostBinding('style.min-width') minWidth: string;
    @Input() width = '100%';
    @Input() color = 'black';
    @Input() align: 'start' | 'center' | 'end' = 'start';
    @Input() focus = false;
    @Input() idSuffix = '';

    @Input() field: string;
    @Input() displayAttribute = 'id';
    @Input() valueAttribute: string = null;
    @Input() preSelectDefaultValue = true;
    @Input() suppressLabel = false;
    @Input() suppressError = false;
    @Input() options$: Observable<any>;
    @Input() multiple = false;
    @Input() tagsMode = false;
    @Input() compareFn: (item1, item2) => boolean;
    @Input() compareAttribute = 'id';
    @Input() customDisplayFn: (value: any) => string;
    @Input() readonly = false;
    @Input() allowClear = true;
    @Input() set labelOverride(label: string) {
        this._labelOverride = label;
        this.setLabel(label);
    }
    @Input() disableOption: (option) => boolean;
    @Input() placeholder: string = 'Choose';
    @Input() maxDisplayed = 3;

    @Output() selectionChange = new EventEmitter<any>();
    @Output() tabPress = new EventEmitter<any>();
    @Input() enableTracking = false;

    private _defaultValue: any;
    private _options: any;
    faInfoIcon = faInfoCircle;
    openHappened = false;
    _labelOverride: string | null = null;

    @Input() roleSettingSuffix = '';
    @Input() showField = true;
    constructor(
        @Inject(TRANSLATION_BASE) translationBase,
        translateService: TranslateService,
        fgDirective: FormGroupDirective,
        @Optional() mposForm: MposFormDirective,
        private _logService: LogService,
        private changeDetectorRef: ChangeDetectorRef,
        protected roleService: RoleService
    ) {
        super(translationBase, translateService, fgDirective, roleService, mposForm);
    }

    @Input()
    set defaultValue(value: any) {
        if (value) {
            this._defaultValue = value;
            if (this._thisControl && this.isValueExistsInOptions(value, this._options)) {
                this.setControlValueIfNotPresent(value);
            }
        }
    }

    get defaultValue(): any {
        return this._defaultValue;
    }

    @HostListener('keydown.Tab', ['$event'])
    onTabPress($event: KeyboardEvent): void {
        this.tabPress.emit($event);
    }

    @HostListener('window:keydown', ['$event'])
    onControlCPress($event: KeyboardEvent): void {
        if (($event.ctrlKey || $event.metaKey) && $event.code === 'KeyC' && this.nzSelectElement.nzOpen) {
            navigator.clipboard.writeText(this.getLabel(this.nzSelectElement.activatedValue));
        }
    }

    focusNow(): void {
        if (this.nzSelectElement) {
            this.nzSelectElement.focus();
        }
    }

    registerOpen(opened: Event): void {
        if (!opened) {
            // i.e. dropdown was closed
            setTimeout(() => this.focusNow(), 0);
        }
    }

    getPosition(): string {
        if (this.align !== 'center') {
            return 'flex-' + this.align;
        }
        return this.align;
    }

    getLabel(option: any): string {
        if (this.customDisplayFn) {
            return this.customDisplayFn(option);
        } else {
            return this.displayAttribute ? option[this.displayAttribute] : option;
        }
    }

    getFieldName(): string {
        return this.field;
    }

    ngOnInit(): void {
        super.ngOnInit();

        if (!this.compareFn) {
            this.compareFn = this.defaultCompareFn(this.compareAttribute || 'id');
        }

        if (!this.disableOption) {
            this.disableOption = this.defaultDisableOption;
        }

        if (!this.options$) {
            this._options = [];
        } else if (this.preSelectDefaultValue || this.defaultValue) {
            this.options$.pipe(takeUntil(this._unsubscribeAll)).subscribe((items) => {
                this._options = items;
                if (items && items.length > 0) {
                    if (this.isValueExistsInOptions(this.defaultValue, items)) {
                        this.setControlValueIfNotPresent(this.defaultValue);
                    } else if (this.preSelectDefaultValue) {
                        this.setControlValueIfNotPresent(items[0]);
                    }
                }
            });
        }
    }

    defaultCompareFn = (attribute: string) => {
        return (c1: any, c2: any) => {
            return c1 && c2 && typeof c1 === 'object' && typeof c2 === 'object' ? c1[attribute] === c2[attribute] : c1 === c2;
        };
    };

    defaultDisableOption = (option: any) => {
        return false;
    };

    private debouncedLog = debounce((value) => {
        this._logService.log('Selection Change', {
            field: this._fieldPath,
            value: value
        });
    }, 500);

    onChange(item: any): void {
        this.selectionChange.emit(item);
        if (this.enableTracking) {
            this.debouncedLog(item);
        }
        // if (this.openHappened) {
        //     console.log('Calling focusNow for ' + this.field);
        //     setTimeout(() => this.focusNow(), 0);
        // }
    }

    setControlValueIfNotPresent(value: any): void {
        if (!this._thisControl.value) {
            this._thisControl.setValue(this.getValue(value));
            this.changeDetectorRef.detectChanges();
        }
    }

    isValueExistsInOptions(value: any, options: any): boolean {
        return value && options && options.find((element) => element.id === value.id);
    }
    getLabelOverride(): any {
        return this._labelOverride;
    }

    getValue(option: any) {
        return this.valueAttribute ? option[this.valueAttribute] : option;
    }

    getMode(): 'default' | 'multiple' | 'tags' {
        if (this.tagsMode) {
            return 'tags';
        }
        if (this.multiple) {
            return 'multiple';
        }
        return 'default';
    }
}
