import {Directive, ElementRef, Input, OnInit, Renderer2} from '@angular/core';
import {Observable} from 'rxjs';

import {DiUserInputGroupDirective} from 'app/common/mpos-forms/directives/di-user-input-group.directive';

@Directive({
    selector: '[diErrorChecker]'
})
export class DiErrorCheckerDirective implements OnInit {
    @Input('diErrorChecker') target: ElementRef;
    @Input() diErrorCheckSources: DiUserInputGroupDirective[] = [];
    @Input() diErrorCheckAction: (arg: boolean, errors?: string[]) => void;
    @Input() diErrorCheckTrigger: Observable<boolean>;
    @Input() errorClass = 'di-error';

    effectiveTarget: ElementRef = null;
    effectiveAction: (arg: boolean, errors?: string[]) => void;

    constructor(
        private selfRef: ElementRef,
        private renderer: Renderer2
    ) {}

    ngOnInit(): void {
        this.effectiveTarget = this.target || this.selfRef;
        this.effectiveAction = this.diErrorCheckAction || this.defaultAction;
        if (!this.diErrorCheckSources || !this.diErrorCheckSources.length) {
            throw new Error('diErrorChecker directive needs diErrorCheckSources to be specified!');
        }
        if (!this.diErrorCheckTrigger) {
            throw new Error('diErrorChecker directive needs diErrorCheckTrigger to be specified!');
        }
        this.diErrorCheckTrigger.subscribe((check) => {
            if (check) {
                const validationErrors: string[] = [];
                this.diErrorCheckSources.forEach((source) => {
                    const srcValidationErrors = source.getValidationErrors();
                    if (srcValidationErrors) {
                        validationErrors.push(...srcValidationErrors);
                    }
                });
                this.effectiveAction(true, validationErrors);
            } else {
                this.effectiveAction(false, []);
            }
        });
    }

    defaultAction(check: boolean, errors: string[]): void {
        if (this.effectiveTarget) {
            if (check && errors.length) {
                this.renderer.addClass(this.effectiveTarget.nativeElement, this.errorClass);
            } else {
                this.renderer.removeClass(this.effectiveTarget.nativeElement, this.errorClass);
            }
        }
    }
}
