import {Directive, ElementRef, Input, OnInit} from '@angular/core';

@Directive({
    selector: '[mposForm]'
})
export class MposFormDirective implements OnInit {
    @Input('mposForm') formName: string;

    private _name: string;

    constructor(private elRef: ElementRef) {}

    ngOnInit(): void {
        if (this.formName) {
            this._name = this.formName;
        } else if (this.elRef.nativeElement instanceof HTMLFormElement) {
            const form: HTMLFormElement = this.elRef.nativeElement as HTMLFormElement;
            this._name = form.name;
        }
    }

    get name(): string {
        return this._name;
    }
}
