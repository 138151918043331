import {Image} from 'app/blocks/model/image.model';
import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Resource} from 'app/constants/resource';
import {IImage} from 'app/blocks/model/image.model';
import EntityCrudService from 'app/blocks/service/api/entity-service';
import {Observable} from 'rxjs';
import {ImageVariant} from 'app/blocks/enum/image.variant.enum';

@Injectable({
    providedIn: 'root'
})
export class ImageService extends EntityCrudService<IImage> {
    constructor(http: HttpClient) {
        super(http, Resource.IMAGES, (obj?) => new Image(obj));
    }

    async downloadB64Image(imageId: number) {
        const data: Blob = await this.http.get(`${this._resourceUrl}/${imageId}/download`, {responseType: 'blob'}).toPromise();
        const reader = new FileReader();
        const b64 = new Promise<string>((resolve, reject) => {
            reader.addEventListener(
                'load',
                () => {
                    resolve(reader.result.toString());
                },
                false
            );
            reader.addEventListener(
                'error',
                () => {
                    reject('Error reading the file');
                },
                false
            );
            if (data) {
                reader.readAsDataURL(data);
            }
        });
        return await b64;
    }

    getImageDeliveryUrl(imageId: number, variant: ImageVariant): Observable<string> {
        return this.http.get<string>(`${this._resourceUrl}/${imageId}/delivery-url/${variant}`);
    }
}
