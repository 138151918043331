import {
    Component,
    HostBinding,
    Inject,
    Input,
    OnInit,
    ViewEncapsulation,
    ChangeDetectionStrategy,
    forwardRef,
    Optional,
    EventEmitter,
    Output
} from '@angular/core';
import {FormGroupDirective} from '@angular/forms';
import {TRANSLATION_BASE} from 'app/constants/injection-tokens';
import {faInfoCircle} from '@fortawesome/free-solid-svg-icons';
import {MposFormElement} from 'app/common/mpos-forms/shared/mpos-form-element';
import {MposFormDirective} from 'app/common/mpos-forms/directives/mpos-form.directive';
import {TranslateService} from '@ngx-translate/core';
import {LogService} from 'app/blocks/service/log.service';
import {debounce} from 'lodash';
import {Store} from '@ngxs/store';
import {RoleService} from 'app/blocks/service/api/role.service';

export const formElementProvider: any = {
    provide: MposFormElement,
    useExisting: forwardRef(() => MposTextAreaComponent)
};

@Component({
    selector: 'mpos-textarea',
    template: `
        <nz-form-item [formGroup]="thisFormGroup" [ngStyle]="{'justify-content': getPosition()}" *ngIf="this.showField">
            <div [ngStyle]="{width: width}">
                <nz-form-label *ngIf="!suppressLabel" [nzFor]="fieldLabel$ | async" [nzRequired]="fieldIsRequired" [nzNoColon]="true">
                    <span>
                        {{ fieldLabel$ | async }}
                        <fa-icon *ngIf="fieldHint$ | async as hint" [icon]="faInfoIcon" size="xs" nz-tooltip [nzTooltipTitle]="hint"></fa-icon>
                    </span>
                </nz-form-label>
                <nz-form-control [nzErrorTip]="suppressError ? null : (validationError$ | async)">
                    <textarea
                        id="{{ 'mpos-textarea-' + field + idSuffix }}"
                        [ngStyle]="{width: width, 'max-width': width, borderRadius: '6px'}"
                        [readonly]="!!readonly"
                        formControlName="{{ field }}"
                        nz-input
                        (keyup)="valueChanges($event)"
                        [rows]="rows"></textarea>
                </nz-form-control>
            </div>
        </nz-form-item>
    `,
    styleUrls: ['./mpos-textarea.component.scss'],
    encapsulation: ViewEncapsulation.None,
    providers: [formElementProvider],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class MposTextAreaComponent extends MposFormElement implements OnInit {
    @HostBinding('class') mposInputFlex = 'mpos-input-flex';
    @Input() @HostBinding('class.flex-grow') grow = true;
    @Input() @HostBinding('style.width') outerWidth: string;
    @Input() width = '100%';
    @Input() align: 'start' | 'center' | 'end' = 'start';

    @Input() field: string | null;
    @Input() suppressLabel = false;
    @Input() suppressError = false;
    @Input() rows = 2;
    @Input() type = 'text';
    @Input() errorVariantMap: {[errorKey: string]: string};
    @Input() readonly = false;
    @Input() roleSettingSuffix = '';
    @Input() showField = true;
    @Input() idSuffix = '';
    @Input() enableTracking = false;
    @Input() set labelOverride(label: string) {
        this._labelOverride = label;
        this.setLabel(label);
    }

    _labelOverride: string | null = null;

    // Value changed
    @Output()
    valueChanged: EventEmitter<any> = new EventEmitter();

    faInfoIcon = faInfoCircle;
    changeCounter = 0;

    constructor(
        @Inject(TRANSLATION_BASE) translationBase,
        fgDirective: FormGroupDirective,
        @Optional() mposForm: MposFormDirective,
        private _logService: LogService,
        translateService: TranslateService,
        protected roleService: RoleService
    ) {
        super(translationBase, translateService, fgDirective, roleService, mposForm);
    }

    getFieldName(): string {
        return this.field;
    }

    getPosition(): string {
        if (this.align !== 'center') {
            return 'flex-' + this.align;
        }
        return this.align;
    }

    private debouncedLog = debounce((value) => {
        this._logService.log('TextArea Change', {
            field: this._fieldPath,
            value: value
        });
    }, 500);

    valueChanges(event) {
        // console.log(event.target.value)
        this.valueChanged.emit(event.target.value);
        if (this.enableTracking) {
            this.debouncedLog(event.target.value);
        }
    }

    getLabelOverride(): any {
        return this._labelOverride;
    }

    // get runChangeDetection(): string {
    //     console.log(`Change detection cycle: ${++this.changecounter} ${this.field}`);
    //     return '';
    // }
}
