import {Component, Inject, Input, OnInit, Optional} from '@angular/core';
import {FormGroupDirective} from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';
import {MposFormElement} from 'app/common/mpos-forms/shared/mpos-form-element';
import {TRANSLATION_BASE} from 'app/constants/injection-tokens';
import {MposFormDirective} from 'app/common/mpos-forms/directives/mpos-form.directive';
import {DomSanitizer} from '@angular/platform-browser';
import {Video} from 'app/blocks/model/video.model';
import {VideoService} from 'app/blocks/service/api/video.service';
import {debounceTime, takeUntil} from 'rxjs';
import {Store} from '@ngxs/store';
import {RoleService} from 'app/blocks/service/api/role.service';

@Component({
    selector: 'di-video',
    template: ` <div class="mpos-form-section" style="padding: 5px">
        @if (!suppressLabel) {
            <nz-form-label [nzFor]="fieldLabel$ | async" [nzRequired]="fieldIsRequired" [nzNoColon]="true">
                <span>
                    {{ fieldLabel$ | async }}
                </span>
            </nz-form-label>
        }
        <nz-spin [nzSpinning]="loadingVideo">
            <video [height]="height" [width]="width" controls>
                @if (videoSrc) {
                    <source [src]="_domSanitizationService.bypassSecurityTrustUrl(videoSrc)" type="video/mp4" />
                    Your browser does not support the video tag.
                }
            </video>
        </nz-spin>
        @if (!readonly) {
            <button nz-button nzType="dashed" (click)="uploader.click()" nzSize="small"><span nz-icon nzType="upload"></span> upload</button>
            <input hidden #uploader type="file" id="file" size="20" (change)="handleUploadVideo($event)" />
        }
    </div>`
})
export class DiVideoComponent extends MposFormElement implements OnInit {
    @Input({required: true}) field: string;
    @Input() uploadVideo: (name: string, file: File) => Promise<Video>;
    @Input() height = 200;
    @Input() width = 200;
    @Input() readonly = false;
    @Input() suppressLabel = false;

    videoSrc?: string;
    loadingVideo = false;

    constructor(
        @Inject(TRANSLATION_BASE) translationBase: any,
        fgDirective: FormGroupDirective,
        @Optional() mposForm: MposFormDirective,
        translateService: TranslateService,
        public _domSanitizationService: DomSanitizer,
        public _videoService: VideoService,
        protected roleService: RoleService
    ) {
        super(translationBase, translateService, fgDirective, roleService, mposForm);
    }

    ngOnInit(): void {
        console.log('ngOnInit');
        super.ngOnInit();
        this.handleVideoValueChange(this._thisControl.value);
        this._thisControl.valueChanges
            .pipe(takeUntil(this._unsubscribeAll), debounceTime(100))
            .subscribe((value: Video) => this.handleVideoValueChange(value));
    }

    private handleVideoValueChange(value: Video) {
        if (value == null) {
            return;
        }

        this.loadingVideo = true;
        this._videoService.getVideoDeliveryUrl(value.id).subscribe({
            next: (url) => {
                this.videoSrc = url;
                this.loadingVideo = false;
            },
            error: (error) => {
                console.error('Error getting video url', error);
                this.loadingVideo = false;
            },
            complete: () => {
                this.loadingVideo = false;
            }
        });
    }

    getFieldName(): string {
        return this.field;
    }

    handleUploadVideo(event: Event) {
        const file = (event.target as HTMLInputElement).files[0];
        if (file) {
            if (!this.uploadVideo) {
                throw new Error('uploadVideo function not provided');
            }
            this.loadingVideo = true;
            this.uploadVideo(file.name, file)
                .then((video) => {
                    this._thisControl.setValue(video);
                })
                .finally(() => {
                    this.loadingVideo = false;
                });
        } else {
            console.log('no file selected');
        }
    }
}
