import {TRANSLATION_BASE} from 'app/constants/injection-tokens';
import {Observable} from 'rxjs';

import {Component, OnInit, Input, Inject} from '@angular/core';
import {MposFormSectionDirective} from 'app/common/mpos-forms/directives/mpos-form-section.directive';

@Component({
    selector: 'mpos-tab-label',
    templateUrl: 'mpos-tab-label.component.html'
    // styleUrls: ['./mpos-tab.component.scss']
})
export class MposTabLabelComponent implements OnInit {
    @Input() section: string;

    formSectionError$: Observable<any>;

    constructor(
        @Inject(TRANSLATION_BASE) public readonly translationBase,
        private _mposFormSection: MposFormSectionDirective
    ) {
        this.formSectionError$ = this._mposFormSection.formSectionErrorState$;
    }

    ngOnInit(): void {}
}
