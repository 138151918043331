import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Inject,
    Input,
    OnInit,
    ViewEncapsulation,
    forwardRef,
    Optional,
    HostBinding,
    Renderer2
} from '@angular/core';
import {UntypedFormArray, FormGroupDirective} from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';
import {TRANSLATION_BASE} from 'app/constants/injection-tokens';
import {faInfoCircle} from '@fortawesome/free-solid-svg-icons';
import {MposFormDirective} from 'app/common/mpos-forms/directives/mpos-form.directive';
import {MposFormElement} from 'app/common/mpos-forms/shared/mpos-form-element';
import {LogService} from 'app/blocks/service/log.service';
import {debounce} from 'lodash';
import {Store} from '@ngxs/store';
import {RoleService} from 'app/blocks/service/api/role.service';

export const formElementProvider: any = {
    provide: MposFormElement,
    useExisting: forwardRef(() => MposColorComponent)
};

@Component({
    selector: 'mpos-color',
    template: `
        <nz-form-item [formGroup]="thisFormGroup" [ngStyle]="{'justify-content': getPosition()}" *ngIf="this.showField">
            <div [ngStyle]="{width: width}">
                <nz-form-label *ngIf="!suppressLabel" [nzFor]="fieldLabel$ | async" [nzRequired]="fieldIsRequired" [nzNoColon]="true">
                    <span>
                        {{ fieldLabel$ | async }}
                        <fa-icon *ngIf="fieldHint$ | async as hint" [icon]="faInfoIcon" size="xs" nz-tooltip [nzTooltipTitle]="hint"></fa-icon>
                    </span>
                </nz-form-label>
                <nz-form-control [nzErrorTip]="suppressError ? null : (validationError$ | async)">
                    <nz-input-group [nzAddOnAfter]="colorPickerTemplate">
                        <input
                            nz-input
                            mcc-color-picker-origin
                            id="diTime-{{ uq }}"
                            [ngStyle]="{width: width, 'max-width': width}"
                            name="{{ field }}"
                            formControlName="{{ field }}"
                            readonly
                            [required]="fieldIsRequired" />
                        <ng-template #colorPickerTemplate>
                            <mcc-color-picker
                                [selectedColor]="selectedColor"
                                [usedSizeColors]="5"
                                mcc-connected-color-picker
                                (change)="onSelectionChange($event)"
                                [hideButtons]="true">
                            </mcc-color-picker>
                        </ng-template>
                    </nz-input-group>
                </nz-form-control>
            </div>
        </nz-form-item>
    `,
    styleUrls: ['./mpos-color.component.scss'],
    providers: [formElementProvider],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class MposColorComponent extends MposFormElement implements OnInit {
    @Input() @HostBinding('class.flex-grow') grow = true;
    @Input() @HostBinding('style.width') outerWidth: string;
    @Input() width = '100%';
    @Input() align: 'start' | 'center' | 'end' = 'start';
    @Input() focus = false;

    @Input() field: string;
    @Input() suppressLabel = false;
    @Input() suppressError = false;
    @Input() selectedColor: any;
    @Input() disabled = false;
    @Input() enableTracking = false;

    @Input() roleSettingSuffix = '';
    @Input() showField = true;
    @Input() readonly = false;

    thisArray: UntypedFormArray;
    faInfoIcon = faInfoCircle;
    uq = Date.now() + Math.floor(Math.random() * 100000);

    constructor(
        @Inject(TRANSLATION_BASE) translationBase,
        translateService: TranslateService,
        fgDirective: FormGroupDirective,
        @Optional() mposForm: MposFormDirective,
        private cdRef: ChangeDetectorRef,
        private renderer: Renderer2,
        private _logService: LogService,
        protected roleService: RoleService
    ) {
        super(translationBase, translateService, fgDirective, roleService, mposForm);
    }

    ngOnInit(): void {
        super.ngOnInit();
        this._thisControl.valueChanges.subscribe((change) => {
            this.selectedColor = this._thisControl.value;
            this.cdRef.detectChanges();
        });
        if (this.thisFormGroup.controls[this.field].value) {
            this.selectedColor = this.thisFormGroup.controls[this.field].value;
        }
    }

    ngAfterViewInit(): void {
        if (focus) {
            this.renderer.selectRootElement('#diTime-' + this.uq).focus();
        }
    }

    getFieldName(): string {
        return this.field;
    }

    getPosition(): string {
        if (this.align !== 'center') {
            return 'flex-' + this.align;
        }
        return this.align;
    }

    defaultCompareFn = (c1, c2) => {
        return c1 && c2 ? c1.id === c2.id : c1 === c2;
    };

    private debouncedLog = debounce((value) => {
        this._logService.log('Colour Change', {
            field: this._fieldPath,
            value: value
        });
    }, 500);

    onSelectionChange(color): void {
        this.selectedColor = color;
        if (this.enableTracking) {
            this.debouncedLog(color);
        }
        this.thisFormGroup.patchValue({
            [this.field]: this.selectedColor.replace('#', '')
        });
    }

    onClick(event: Event): void {
        if (this.disabled) {
            event.stopPropagation();
        }
    }
}
