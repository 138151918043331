import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {TranslateModule} from '@ngx-translate/core';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {MposSearchSelectComponent} from 'app/common/mpos-search-select/mpos-search-select.component';
import {NzSelectModule} from 'ng-zorro-antd/select';
import {NzFormModule} from 'ng-zorro-antd/form';
import {NzToolTipModule} from 'ng-zorro-antd/tooltip';
import {NzIconModule} from 'ng-zorro-antd/icon';

@NgModule({
    declarations: [MposSearchSelectComponent],
    imports: [
        CommonModule,
        TranslateModule,
        FormsModule,
        ReactiveFormsModule,
        FontAwesomeModule,
        NzSelectModule,
        NzFormModule,
        NzToolTipModule,
        NzIconModule
    ],
    exports: [MposSearchSelectComponent],
    providers: []
})
export class MposSearchSelectModule {}
