import {ChangeDetectionStrategy, Component, Inject, Input, OnInit, forwardRef, Optional, HostBinding} from '@angular/core';
import {FormGroupDirective, FormControl} from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';
import {TRANSLATION_BASE} from 'app/constants/injection-tokens';
import {faInfoCircle} from '@fortawesome/free-solid-svg-icons';
import {MposFormElement} from 'app/common/mpos-forms/shared/mpos-form-element';
import {MposFormDirective} from 'app/common/mpos-forms/directives/mpos-form.directive';
import {ErrorStateMatcher} from '@angular/material/core';
import {LogService} from 'app/blocks/service/log.service';
import {debounce} from 'lodash';
import {Store} from '@ngxs/store';
import {RoleService} from 'app/blocks/service/api/role.service';

export const formElementProvider: any = {
    provide: MposFormElement,
    useExisting: forwardRef(() => MposTimeComponent)
};

// [(ngModel)]="time"
// [ngModelOptions]="{standalone: true}"
// (ngModelChange)="onChange($event)"

@Component({
    selector: 'mpos-time',
    template: `
        <nz-form-item [formGroup]="thisFormGroup" [ngStyle]="{'justify-content': getPosition()}" *ngIf="this.showField">
            <div [ngStyle]="{width: width}">
                <nz-form-label *ngIf="!suppressLabel" [nzFor]="fieldLabel$ | async" [nzRequired]="fieldIsRequired" [nzNoColon]="true">
                    <span>
                        {{ fieldLabel$ | async }}
                        <fa-icon *ngIf="fieldHint$ | async as hint" [icon]="faInfoIcon" size="xs" nz-tooltip [nzTooltipTitle]="hint"></fa-icon>
                    </span>
                </nz-form-label>
                <nz-form-control [nzErrorTip]="suppressError ? null : (validationError$ | async)">
                    <nz-input-group>
                        <nz-time-picker
                            id="diTime-{{ uq }}"
                            [ngStyle]="{width: width, 'max-width': width}"
                            [nzAutoFocus]="focus"
                            formControlName="{{ field }}"
                            nzFormat="HH:mm"
                            type="time"
                            [nzUse12Hours]="true"
                            [required]="fieldIsRequired"
                            [nzDisabled]="!!readonly"
                            [nzAllowEmpty]="!disabled">
                        </nz-time-picker>
                    </nz-input-group>
                </nz-form-control>
            </div>
        </nz-form-item>
    `,
    styleUrls: ['./mpos-time.component.scss'],
    providers: [formElementProvider],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class MposTimeComponent extends MposFormElement implements OnInit {
    @Input() @HostBinding('class.flex-grow') grow = true;
    @Input() @HostBinding('style.width') outerWidth: string;
    @Input() width = '100%';
    @Input() align: 'start' | 'center' | 'end' = 'start';
    @Input() focus = false;

    @Input() field: string;
    @Input() suppressLabel = false;
    @Input() suppressError = false;
    @Input() customErrorStateMatcher: ErrorStateMatcher;
    @Input() readonly = false;
    @Input() enableTracking = false;
    @Input() roleSettingSuffix = '';
    @Input() showField = true;

    uq = Date.now() + Math.floor(Math.random() * 100000);
    faInfoIcon = faInfoCircle;
    time: Date = null;
    disabled = false;

    constructor(
        @Inject(TRANSLATION_BASE) translationBase,
        translateService: TranslateService,
        fgDirective: FormGroupDirective,
        @Optional() mposForm: MposFormDirective,
        private _logService: LogService,
        protected roleService: RoleService
    ) {
        super(translationBase, translateService, fgDirective, roleService, mposForm);
    }

    ngOnInit(): void {
        super.ngOnInit();
        // this._thisControl.valueChanges
        //     .subscribe(value => {
        //         if (value) {
        //             const [hour, mins] = value.split(':');
        //             const date = new Date();
        //             date.setHours(hour, mins);
        //             this.time = date;
        //         }
        //     });
        // const fControl = this._thisControl as FormControl;
        // fControl.registerOnDisabledChange(dis => {
        //     this.disabled = dis;
        // });
    }

    getFieldName(): string {
        return this.field;
    }

    getPosition(): string {
        if (this.align !== 'center') {
            return 'flex-' + this.align;
        }
        return this.align;
    }

    private debouncedLog = debounce((value) => {
        this._logService.log('TIme Change', {
            field: this._fieldPath,
            value: value
        });
    }, 500);

    onChange(date: Date | null): void {
        if (date) {
            const value = this.padLeftZero(date.getHours(), 2) + ':' + this.padLeftZero(date.getMinutes(), 2);
            this._thisControl.patchValue(value);
            if (this.enableTracking) {
                this.debouncedLog(value);
            }
        }
    }

    private padLeftZero(numberToPad: number, noOfDigits: number): string {
        const str = '' + numberToPad;
        return ('0'.repeat(noOfDigits) + str).substring(str.length);
    }
}
