import {ChangeDetectionStrategy, Component, Inject, Input, OnInit, forwardRef, Optional} from '@angular/core';
import {FormGroupDirective} from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';
import {TRANSLATION_BASE} from 'app/constants/injection-tokens';
import {isObservable, Observable, of} from 'rxjs';

import {MposFormElement} from 'app/common/mpos-forms/shared/mpos-form-element';
import {MposFormDirective} from 'app/common/mpos-forms/directives/mpos-form.directive';
import {Store} from '@ngxs/store';
import {RoleService} from 'app/blocks/service/api/role.service';

export const formElementProvider: any = {
    provide: MposFormElement,
    useExisting: forwardRef(() => MposCheckboxListComponent)
};

@Component({
    selector: 'mpos-checkbox-list',
    template: `
        <span *ngIf="this.showField">
            <ng-container [formGroup]="thisFormGroup" *ngFor="let option of options; let i = index">
                <div formGroupName="{{ field }}" [ngStyle]="getStyle()">
                    <label id="{{ 'mpos-checkbox-list-' + field + idSuffix + '-' + getOptionForId(option) }}" nz-checkbox [formControlName]="i"
                        >{{ option }}
                    </label>
                </div>
            </ng-container>
        </span>
    `,
    providers: [formElementProvider],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class MposCheckboxListComponent extends MposFormElement implements OnInit {
    @Input() width: string;
    @Input() idSuffix = '';
    @Input() field: string;
    @Input() options$: Observable<any>;
    @Input() roleSettingSuffix = '';
    @Input() showField = true;
    @Input() readonly = false;

    options: any[] = [];

    constructor(
        @Inject(TRANSLATION_BASE) translationBase,
        translateService: TranslateService,
        fgDirective: FormGroupDirective,
        @Optional() mposForm: MposFormDirective,
        protected roleService: RoleService
    ) {
        super(translationBase, translateService, fgDirective, roleService, mposForm);
    }

    getFieldName(): string {
        return this.field;
    }

    ngOnInit(): void {
        super.ngOnInit();
        if (!isObservable(this.options$)) {
            this.options$ = of(this.options$);
        }
        this.options$.subscribe((result) => {
            this.options = result;
        });
    }

    getStyle(): any {
        const style = {};
        if (this.width) {
            style['width'] = this.width;
        }
        return style;
    }

    getOptionForId(option: any): string {
        if (option) {
            let label = option.toString(); // Convert option to string
            // Remove empty spaces and replace them with "-"
            label = label.replace(/\s+/g, '-');
            // Remove characters other than alphabets, numbers, and "-"
            label = label.replace(/[^a-zA-Z0-9-]/g, '');
            return label;
        }
        return ''; // Return empty string if option is falsy
    }
}
