import {ChangeDetectionStrategy, Component, EventEmitter, forwardRef, HostBinding, Inject, Input, OnInit, Optional, Output} from '@angular/core';
import {FormGroupDirective} from '@angular/forms';
import {faInfoCircle} from '@fortawesome/free-solid-svg-icons';
import {TranslateService} from '@ngx-translate/core';
import {TRANSLATION_BASE} from 'app/constants/injection-tokens';

import {MposFormDirective} from 'app/common/mpos-forms/directives/mpos-form.directive';
import {MposFormElement} from 'app/common/mpos-forms/shared/mpos-form-element';
import {ViewChild, HostListener} from '@angular/core';
import {NzCascaderComponent, NzCascaderOption} from 'ng-zorro-antd/cascader';
import {Store} from '@ngxs/store';
import {RoleService} from 'app/blocks/service/api/role.service';

export const formElementProvider: any = {
    provide: MposFormElement,
    useExisting: forwardRef(() => MposCascadeSelectComponent)
};

@Component({
    selector: 'mpos-cascade-select',
    template: `
        <nz-form-item [formGroup]="thisFormGroup" [ngStyle]="{'justify-content': getPosition()}" *ngIf="this.showField">
            <div [ngStyle]="{width: width}">
                <nz-form-label *ngIf="!suppressLabel" [nzFor]="fieldLabel$ | async" [nzRequired]="fieldIsRequired" [nzNoColon]="true">
                    <span>
                        {{ fieldLabel$ | async }}
                        <fa-icon *ngIf="fieldHint$ | async as hint" [icon]="faInfoIcon" size="xs" nz-tooltip [nzTooltipTitle]="hint"></fa-icon>
                    </span>
                </nz-form-label>
                <nz-form-control [nzErrorTip]="suppressError ? null : (validationError$ | async)">
                    <nz-input-group>
                        <nz-cascader [nzOptions]="options" [formControlName]="field" (ngModelChange)="onChange($event)"></nz-cascader>
                    </nz-input-group>
                </nz-form-control>
            </div>
        </nz-form-item>
    `,
    styleUrls: ['./mpos-cascade-select.component.scss'],
    providers: [formElementProvider],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class MposCascadeSelectComponent extends MposFormElement implements OnInit {
    @ViewChild(NzCascaderComponent, {static: true})
    nzSelectElement: NzCascaderComponent;

    @Input() @HostBinding('class.flex-grow') grow = true;
    @Input() @HostBinding('style.width') outerWidth: string;
    @Input() width = '100%';
    @Input() align: 'start' | 'center' | 'end' = 'start';
    @Input() focus = false;
    @Input() showField = true;
    @Input() readonly = false;

    @Input() field: string;
    @Input() displayAttribute = 'id';
    @Input() preSelectDefaultValue = true;
    @Input() suppressLabel = false;
    @Input() suppressError = false;
    @Input() options: NzCascaderOption[];
    @Input() customDisplayFn: (value: any) => string;
    @Input() values: string[];

    @Output() selectionChange = new EventEmitter<any>();
    @Output() tabPress = new EventEmitter<any>();

    faInfoIcon = faInfoCircle;
    openHappened = false;

    constructor(
        @Inject(TRANSLATION_BASE) translationBase,
        translateService: TranslateService,
        fgDirective: FormGroupDirective,
        @Optional() mposForm: MposFormDirective,
        protected roleService: RoleService
    ) {
        super(translationBase, translateService, fgDirective, roleService, mposForm);
    }
    @HostListener('keydown.Tab', ['$event'])
    onTabPress($event: KeyboardEvent): void {
        this.tabPress.emit($event);
    }

    focusNow(): void {
        if (this.nzSelectElement) {
            this.nzSelectElement.focus();
        }
    }

    getPosition(): string {
        if (this.align !== 'center') {
            return 'flex-' + this.align;
        }
        return this.align;
    }

    getLabel(option: any): string {
        if (this.customDisplayFn) {
            return this.customDisplayFn(option);
        } else {
            return this.displayAttribute ? option[this.displayAttribute] : option;
        }
    }

    getFieldName(): string {
        return this.field;
    }

    onChange(item: any): void {
        this.selectionChange.emit(item);
    }
    //

    // updateBinInfos(bins: any): void {
    //     this.binsFormArray.controls.splice(0);
    //     if (bins && bins.length > 0) {
    //         for (let i = 0; i < bins.length; i++) {
    //             this.binsFormArray.push(createBinForm(this._formBuilder));
    //             this.binsFormArray.controls[i].patchValue({id: [String(bins[i].warehouse.warehouseName), String(bins[i].location)]});
    //             console.log(this.binsFormArray.controls[i].get('id').value);
    //         }
    //     }
    // }

    // getCascadeOptions(): void {
    //     let warehouses = [];
    //     this.warehouseService.getWarehouseBins().then(data => {
    //         warehouses = data.body;
    //         warehouses.forEach(warehouse => {
    //         const bins = [];
    //         warehouse.bins.forEach(bin => {
    //                 bins.push({value: String(bin.id), label: bin.location, isLeaf: true});
    //             });
    //         const warehouseBins = {value: String(warehouse.id), label: warehouse.warehouseName, children: bins};
    //         this.options.push(warehouseBins);
    //         });
    //     });
    // }
}
