import {IBaseEntity, BaseEntity} from 'app/blocks/model/base-entity.model';
import {IProductAttribute} from 'app/blocks/model/product-attirbute.model';
import {MediaAssetType} from 'app/blocks/enum/media-asset-type.enum';

export interface IImage extends IBaseEntity {
    id?: number;
    name?: string;
    originalFileName: string;
    path: string;
    width?: number;
    height?: number;
    image?: string;
    multipartFile?: File;
    sequence?: number;
    attributes: IProductAttribute[];
    mediaAssetType?: MediaAssetType;
    type?: string;
}

export class Image extends BaseEntity implements IImage {
    public id?: number;
    public name?: string;
    public originalFileName: string;
    public path: string;
    public width?: number;
    public height?: number;
    public image?: string;
    public multipartFile?: File;
    public sequence?: number;
    public attributes: IProductAttribute[] = [];
    public mediaAssetType?: MediaAssetType;
    public type?: string;

    constructor(image?) {
        super();
        if (image) {
            this.id = image.id;
            this.name = image.name;
            this.originalFileName = image.originalFileName;
            this.path = image.path;
            this.width = image.width;
            this.height = image.height;
            this.sequence = image.sequence;
            this.attributes = image.attributes ?? [];
            this.mediaAssetType = image.mediaAssetType;
            this.type = image.type;
        } else {
        }
    }

    get discriminator(): any {
        return this.name;
    }

    clone(): Image {
        return new Image(this);
    }
}
