import {OnInit, OnDestroy, Optional, Directive} from '@angular/core';
import {AbstractControl, UntypedFormGroup, FormGroupDirective} from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';
import {Observable, Subject, BehaviorSubject} from 'rxjs';

import {MposFormDirective} from 'app/common/mpos-forms/directives/mpos-form.directive';
import {Store} from '@ngxs/store';
import {DEBUG_PAGE_PARAMS, DEBUG_ROLE_KEYS} from 'app/app.constants';
import {RoleService} from 'app/blocks/service/api/role.service';

export interface ValidationError {
    key: string;
    params: any;
}

@Directive()
export abstract class MposFormElement implements OnInit, OnDestroy {
    static DEFAULT_FIELD_GENDER = 'm';

    // Accessed by the template
    public field; // Inputs()
    public thisFormGroup: UntypedFormGroup;
    public fieldIsRequired = false;

    // Not accessed directly by the template
    public errorVariantMap: {[errorKey: string]: string}; // Inputs()

    protected _thisControl: AbstractControl;
    protected _fieldPath: string;
    protected _fieldGender = MposFormElement.DEFAULT_FIELD_GENDER;

    // Computed
    private _validationErrorSubject = new BehaviorSubject<string>(null);
    public validationError$: Observable<string> = this._validationErrorSubject.asObservable();

    protected _validationError: ValidationError;
    protected _languageParams: any = {};

    private _fieldLabelSubject = new BehaviorSubject<string>('');
    public fieldLabel$: Observable<string> = this._fieldLabelSubject.asObservable();

    private _fieldHintSubject = new BehaviorSubject<string>('');
    public fieldHint$: Observable<string> = this._fieldHintSubject.asObservable();

    protected _unsubscribeAll = new Subject<void>();

    public roleSettingSuffix = '';
    public roleSettingMenuId = '';
    public showField = true;
    public readonly = false;

    constructor(
        protected readonly _translationBase,
        protected readonly _translateService: TranslateService,
        protected readonly _fgDirective: FormGroupDirective,
        protected roleService: RoleService,
        @Optional() protected readonly _mposForm: MposFormDirective
    ) {}

    // TODO: Handle this in the end
    abstract getFieldName(): string;

    ngOnInit(): void {
        this.thisFormGroup = this._fgDirective.control;
        this._thisControl = this.thisFormGroup.get(this.getFieldName());
        this._computeI18nFieldKeyPath(this.getFieldName());
        this._computeRequired();
        this._fetchFieldGender();
        this._fetchHint();

        this._computeValidationError();
        this._thisControl.statusChanges.subscribe((status) => {
            if (status === 'INVALID') {
                this._computeValidationError();
            } else {
                this._setValidationError(null);
            }
        });

        this._fetchLanguageParams();
        this._translateService.onLangChange.subscribe((_) => {
            this._fetchLanguageParams();
        });

        this._thisControl.valueChanges.subscribe((value) => this.onValueChange(value));
        this.roleSettingMenuId = this._fieldPath + this.roleSettingSuffix;
        if (DEBUG_ROLE_KEYS) {
            console.log("Control: '" + this.roleSettingMenuId + "'");
        }
        if (!this.readonly) {
            this.roleService.subscribeToEnableSetting(this.roleSettingMenuId).subscribe((enable) => {
                this.readonly = !enable;
            });
        }
        if (this.showField) {
            this.roleService.subscribeToShowSetting(this.roleSettingMenuId).subscribe((show) => {
                this.showField = show;
            });
        }
    }

    ngOnDestroy(): void {
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    getValidatorError(): string | null {
        return this._validationErrorSubject.getValue();
    }

    private _setValidationError(validationError: ValidationError): void {
        this._validationError = validationError;
        if (!validationError) {
            this._validationErrorSubject.next(null);
        } else {
            this._emitValidationError();
        }
    }

    onValueChange(result: any): any {}

    private _setLanguageParams(languageParams: any): void {
        this._languageParams = languageParams;
        if (this._validationError) {
            this._emitValidationError();
        }
    }

    private _emitValidationError(): void {
        const newValidationError = Object.assign({}, this._validationError);
        Object.assign(newValidationError.params, this._languageParams);
        this._translateService.get(newValidationError.key, newValidationError.params).subscribe((errorMessage) => {
            this._validationErrorSubject.next(errorMessage);
        });
    }

    private _fetchLanguageParams(): void {
        Promise.all([this._fetchFieldNameLabel(), this._fetchLanguageGenderWords(), this._fetchHint()]).then((results) => {
            this._fieldLabelSubject.next(results[0]);
            this._setLanguageParams(Object.assign({}, {_fieldName: results[0]}, results[1]));
            const hint = results[2] !== this.hintKey ? results[2] : '';
            this._fieldHintSubject.next(hint);
        });
    }

    private _fetchFieldNameLabel(): Promise<any> {
        // @ts-ignore
        if (this.getLabelOverride()) {
            return Promise.resolve(this.getLabelOverride());
        }
        return this._translateService.get(this.labelKey).toPromise();
    }

    getLabelOverride(): void {
        return null;
    }

    protected setLabel(label: string): void {
        this._fieldLabelSubject.next(label);
    }

    private _fetchHint(): Promise<any> {
        return this._translateService.get(this.hintKey).toPromise();
    }

    private _fetchLanguageGenderWords(): Promise<any> {
        return this._translateService.get('common.genderWords.' + this._fieldGender).toPromise();
    }

    private _computeValidationError(): void {
        this._validationError = null;
        for (const errorProperty in this._thisControl.errors) {
            if (this._thisControl.errors.hasOwnProperty(errorProperty)) {
                const errorVariant = this.errorVariants[errorProperty] || 'default';
                const errorParams = this._thisControl.errors[errorProperty];

                let validationErrorParams = {};
                if (errorParams !== null && typeof errorParams === 'object') {
                    validationErrorParams = errorParams;
                } else {
                    validationErrorParams[errorProperty] = errorParams;
                }

                this._setValidationError({
                    key: `common.forms.validationMessages.${errorProperty}.${errorVariant}`,
                    params: validationErrorParams
                });
            }
        }
    }

    get hintKey(): string {
        return this._fieldPath + '.hint';
    }

    private get labelKey(): string {
        return this._fieldPath + '.label';
    }

    private get genderKey(): string {
        return this._fieldPath + '.gender';
    }

    private get errorVariants(): any {
        return this.errorVariantMap ? this.errorVariantMap : {};
    }

    private _computeI18nFieldKeyPath(thisFieldName: string): void {
        const pathElements = [];
        let currentFormGroup: any = this.thisFormGroup;
        let parentFormGroup: any = currentFormGroup.parent;

        while (parentFormGroup) {
            const parentControls = parentFormGroup.controls;
            const currentControlName = Object.keys(parentControls).find((name) => parentControls[name] === currentFormGroup);
            pathElements.unshift(currentControlName);
            currentFormGroup = parentFormGroup;
            parentFormGroup = parentFormGroup.parent;
        }

        const filteredPathElements = pathElements.filter((element) => isNaN(element));
        this._fieldPath = [
            this._translationBase,
            'forms',
            this._mposForm && this._mposForm.name ? this._mposForm.name : 'entityForm',
            'fields',
            ...filteredPathElements,
            thisFieldName
        ].join('.');
    }

    private _computeRequired(): void {
        if (this._thisControl.validator) {
            const validator = this._thisControl.validator({} as AbstractControl);
            this.fieldIsRequired = validator && validator.required;
        }
    }

    private _fetchFieldGender(): void {
        this._translateService.get(this.genderKey).subscribe((fieldGender) => {
            if (fieldGender !== this.genderKey) {
                this._fieldGender = fieldGender;
            } else {
                this._fieldGender = MposFormElement.DEFAULT_FIELD_GENDER;
            }
        });
    }
}
