import {ChangeDetectionStrategy, Component, Inject, Input, OnInit, Output, EventEmitter, forwardRef, Optional} from '@angular/core';
import {FormGroupDirective} from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';
import {TRANSLATION_BASE} from 'app/constants/injection-tokens';

import {MposFormElement} from 'app/common/mpos-forms/shared/mpos-form-element';
import {MposFormDirective} from 'app/common/mpos-forms/directives/mpos-form.directive';
import {MposCheckboxParams} from 'app/common/mpos-forms/shared/mpos-checkbox-params';
import {faInfoCircle} from '@fortawesome/free-solid-svg-icons';
import {LogService} from 'app/blocks/service/log.service';
import {debounce} from 'lodash';
import {RoleService} from 'app/blocks/service/api/role.service';

export const formElementProvider: any = {
    provide: MposFormElement,
    useExisting: forwardRef(() => MposCheckboxComponent)
};

@Component({
    selector: 'mpos-checkbox',
    template: `
        <nz-form-item [formGroup]="thisFormGroup" style="width: 100%" *ngIf="this.showField">
            <label
                id="{{ 'mpos-checkbox-' + field + idSuffix }}"
                nz-checkbox
                formControlName="{{ field }}"
                [nzDisabled]="readonly"
                (ngModelChange)="onChange($event)"
                [nzIndeterminate]="checkboxParams.indeterminate">
                <span *ngIf="!suppressLabel">
                    {{ fieldLabel$ | async }}
                    <fa-icon *ngIf="fieldHint$ | async as hint" [icon]="faInfoIcon" size="xs" nz-tooltip [nzTooltipTitle]="hint"> </fa-icon>
                </span>
            </label>
        </nz-form-item>
    `,
    providers: [formElementProvider],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class MposCheckboxComponent extends MposFormElement implements OnInit {
    @Input() field: string;
    @Input() idSuffix = '';
    @Input() suppressLabel = false;
    @Input() roleSettingSuffix = '';
    @Input() showField = true;
    @Input() readonly = false;
    @Input() checkboxParams: MposCheckboxParams = {
        labelPosition: 'after',
        indeterminate: false
    };
    @Input() set labelOverride(label: string) {
        this._labelOverride = label;
        this.setLabel(label);
    }
    @Input() enableTracking = false;
    faInfoIcon = faInfoCircle;
    _labelOverride: string | null = null;

    @Output() valueChange: EventEmitter<any> = new EventEmitter<any>();

    constructor(
        @Inject(TRANSLATION_BASE) translationBase,
        translateService: TranslateService,
        fgDirective: FormGroupDirective,
        private _logService: LogService,
        @Optional() mposForm: MposFormDirective,
        protected roleService: RoleService
    ) {
        super(translationBase, translateService, fgDirective, roleService, mposForm);
    }

    getFieldName(): string {
        return this.field;
    }

    getLabelOverride(): any {
        return this._labelOverride;
    }

    private debouncedLog = debounce((value) => {
        this._logService.log('Checkbox Change', {
            field: this._fieldPath,
            value: value
        });
    }, 500);

    onChange($event): void {
        this.valueChange.emit($event);
        if (this.enableTracking) {
            this.debouncedLog($event);
        }
    }
}
