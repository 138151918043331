import {ChangeDetectionStrategy, Component, Inject, Input, OnInit, forwardRef, Optional, Output, EventEmitter} from '@angular/core';
import {FormGroupDirective} from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';
import {TRANSLATION_BASE} from 'app/constants/injection-tokens';

import {MposFormElement} from 'app/common/mpos-forms/shared/mpos-form-element';
import {MposFormDirective} from 'app/common/mpos-forms/directives/mpos-form.directive';
import {faInfoCircle} from '@fortawesome/free-solid-svg-icons';
import {ANALYTICS_LINK} from 'app/constants/setting';
import {LogService} from 'app/blocks/service/log.service';
import {debounce} from 'lodash';
import {RoleService} from 'app/blocks/service/api/role.service';

export const formElementProvider: any = {
    provide: MposFormElement,
    useExisting: forwardRef(() => MposSliderToggleComponent)
};

@Component({
    selector: 'mpos-slider-toggle',
    template: `
        <nz-form-item [formGroup]="thisFormGroup" style="width: 100%" *ngIf="showField">
            <nz-form-label *ngIf="!suppressLabel" [nzFor]="fieldLabel$ | async" [nzRequired]="fieldIsRequired" [nzNoColon]="true">
                <span>
                    {{ fieldLabel$ | async }}
                    <fa-icon *ngIf="fieldHint$ | async as hint" [icon]="faInfoIcon" size="xs" nz-tooltip [nzTooltipTitle]="hint"></fa-icon>
                </span>
            </nz-form-label>
            <nz-form-control [nzErrorTip]="suppressError ? null : (validationError$ | async)">
                <nz-switch
                    id="{{ 'mpos-slider-toggle-' + field + idSuffix }}"
                    [nzDisabled]="!!readonly"
                    formControlName="{{ field }}"
                    (ngModelChange)="onChange($event)">
                </nz-switch>
            </nz-form-control>
        </nz-form-item>
    `,
    styleUrls: ['./mpos-slider-toggle.component.scss'],
    providers: [formElementProvider],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class MposSliderToggleComponent extends MposFormElement implements OnInit {
    faInfoIcon = faInfoCircle;
    @Input() field: string;
    @Input() idSuffix = '';
    @Input() suppressLabel = false;
    @Input() suppressError = false;
    @Input() readonly = false;
    @Input() roleSettingSuffix = '';
    @Input() showField = true;
    @Output() valueChange = new EventEmitter<any>();
    @Input() enableTracking = false;
    userInputForToggle: boolean = null;

    constructor(
        @Inject(TRANSLATION_BASE) translationBase,
        translateService: TranslateService,
        fgDirective: FormGroupDirective,
        @Optional() mposForm: MposFormDirective,
        private _logService: LogService,
        protected roleService: RoleService
    ) {
        super(translationBase, translateService, fgDirective, roleService, mposForm);
    }

    getFieldName(): string {
        return this.field;
    }

    private debouncedLog = debounce((value) => {
        this._logService.log('Slider Change', {
            field: this._fieldPath,
            value: value
        });
    }, 500);

    onChange(item: any): void {
        if (item === null) {
            return;
        }

        if (this.enableTracking) {
            this.debouncedLog(item);
        }

        //this will ensure that the onChange will not be triggered on hot reload or during navigation
        //only be triggered when the user toggles between the values
        if (this.userInputForToggle !== undefined) {
            this.valueChange.emit(item);
        }

        this.userInputForToggle = item;
    }
}
