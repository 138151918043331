import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MAT_DATE_LOCALE, DateAdapter, MAT_DATE_FORMATS} from '@angular/material/core';
import {MomentDateAdapter, MAT_MOMENT_DATE_FORMATS} from '@angular/material-moment-adapter';
import {TranslateModule} from '@ngx-translate/core';

import {MposCheckboxComponent} from 'app/common/mpos-forms/components/mpos-checkbox/mpos-checkbox.component';
import {MposInputComponent} from 'app/common/mpos-forms/components/mpos-input/mpos-input.component';
import {MposTextAreaComponent} from 'app/common/mpos-forms/components/mpos-textarea/mpos-textarea.component';
import {MposSelectComponent} from 'app/common/mpos-forms/components/mpos-select/mpos-select.component';
import {MposSliderToggleComponent} from 'app/common/mpos-forms/components/mpos-slider-toggle/mpos-slider-toggle.component';
import {MposFormGroupDirective} from 'app/common/mpos-forms/directives/mpos-form-group.directive';
import {MposFormDirective} from 'app/common/mpos-forms/directives/mpos-form.directive';
import {MposDateComponent} from 'app/common/mpos-forms/components/mpos-date/mpos-date.component';
// import {AmazingTimePickerModule} from 'amazing-time-picker';
import {MposTimeComponent} from 'app/common/mpos-forms/components/mpos-time/mpos-time.component';
import {MposDualListComponent} from 'app/common/mpos-forms/components/mpos-dual-list/mpos-dual-list.component';
import {AngularDualListBoxModule} from 'angular-dual-listbox';
import {MccColorPickerModule} from 'material-community-components/color-picker';
import {MposColorComponent} from 'app/common/mpos-forms/components/mpos-color/mpos-color.component';
import {MposFormSectionDirective} from 'app/common/mpos-forms/directives/mpos-form-section.directive';
import {MposTabLabelComponent} from 'app/common/mpos-forms/components/mpos-tab-label/mpos-tab-label.component';
import {NgSelectModule} from '@ng-select/ng-select';
import {CustomListboxComponent} from 'app/common/mpos-forms/components/custom-dual-listbox/custom-listbox.component';
import {MposCheckboxListComponent} from 'app/common/mpos-forms/components/mpos-checkbox-list/mpos-checkbox-list.component';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {MposAllOrSelectedFilterPipe} from 'app/common/mpos-forms/pipes/all-or-selected-filter.pipe';
import {CurrencyPipe} from 'app/common/mpos-forms/pipes/currency.pipe';
import {UserEmployeePipe} from 'app/common/mpos-forms/pipes/user-employee.pipe';
import {NzInputModule} from 'ng-zorro-antd/input';
import {NzInputNumberModule} from 'ng-zorro-antd/input-number';
import {NzFormModule} from 'ng-zorro-antd/form';
import {NzToolTipModule} from 'ng-zorro-antd/tooltip';
import {NzTimePickerModule} from 'ng-zorro-antd/time-picker';
import {NzSelectModule} from 'ng-zorro-antd/select';
import {NzIconModule} from 'ng-zorro-antd/icon';
import {NzSwitchModule} from 'ng-zorro-antd/switch';
import {NzCheckboxModule} from 'ng-zorro-antd/checkbox';
import {NzDatePickerModule} from 'ng-zorro-antd/date-picker';
import {DiUserInputGroupDirective} from 'app/common/mpos-forms/directives/di-user-input-group.directive';
import {DiErrorCheckerDirective} from 'app/common/mpos-forms/directives/di-error-checker.directive';
import {MposSearchSelectComponent} from 'app/common/mpos-search-select/mpos-search-select.component';
import {MposSearchSelectModule} from 'app/common/mpos-search-select/mpos-search-select.module';
import {MposCascadeSelectComponent} from 'app/common/mpos-forms/components/mpos-cascade-select/mpos-cascade-select';
import {NzCascaderModule} from 'ng-zorro-antd/cascader';
import {DiImageComponent} from 'app/common/mpos-forms/components/di-image/di-image.component';
import {NzSpinModule} from 'ng-zorro-antd/spin';
import {NzButtonModule} from 'ng-zorro-antd/button';
import {DiVideoComponent} from 'app/common/mpos-forms/components/di-video/di-video.component';
import {DiMediaAssetComponent} from 'app/common/mpos-forms/components/di-media-asset/di-media-asset.component';

@NgModule({
    declarations: [
        MposInputComponent,
        MposTextAreaComponent,
        MposSelectComponent,
        MposSliderToggleComponent,
        MposCheckboxComponent,
        MposDateComponent,
        MposTimeComponent,
        MposDualListComponent,
        MposColorComponent,
        CustomListboxComponent,
        MposTabLabelComponent,
        MposCheckboxListComponent,
        MposAllOrSelectedFilterPipe,
        MposFormGroupDirective,
        MposFormDirective,
        MposFormSectionDirective,
        DiUserInputGroupDirective,
        DiErrorCheckerDirective,
        CurrencyPipe,
        UserEmployeePipe,
        MposCascadeSelectComponent,
        DiImageComponent,
        DiVideoComponent,
        DiMediaAssetComponent
    ],
    imports: [
        CommonModule,
        TranslateModule,
        FormsModule,
        MposSearchSelectModule,
        ReactiveFormsModule,
        // AmazingTimePickerModule,
        NgSelectModule,
        AngularDualListBoxModule,
        MccColorPickerModule.forRoot({
            empty_color: '#000'
        }),
        FontAwesomeModule,
        NzInputModule,
        NzInputNumberModule,
        NzFormModule,
        NzToolTipModule,
        NzTimePickerModule,
        NzSelectModule,
        NzIconModule,
        NzSwitchModule,
        NzCheckboxModule,
        NzDatePickerModule,
        NzCascaderModule,
        NzSpinModule,
        NzButtonModule
    ],
    exports: [
        MposInputComponent,
        MposTextAreaComponent,
        MposSelectComponent,
        MposSliderToggleComponent,
        MposCheckboxComponent,
        MposSearchSelectComponent,
        MposDateComponent,
        MposTimeComponent,
        MposDualListComponent,
        MposColorComponent,
        CustomListboxComponent,
        MposTabLabelComponent,
        MposCheckboxListComponent,
        MposAllOrSelectedFilterPipe,
        MposFormGroupDirective,
        MposFormDirective,
        MposFormSectionDirective,
        DiUserInputGroupDirective,
        DiErrorCheckerDirective,
        CurrencyPipe,
        UserEmployeePipe,
        MposCascadeSelectComponent,
        DiImageComponent,
        DiVideoComponent,
        DiMediaAssetComponent
    ],
    providers: [
        {provide: MAT_DATE_LOCALE, useValue: 'en-GB'},
        {
            provide: DateAdapter,
            useClass: MomentDateAdapter,
            deps: [MAT_DATE_LOCALE]
        },
        {provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS}
    ]
})
export class MposFormsModule {}
