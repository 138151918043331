import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, Input, OnInit, forwardRef, Optional} from '@angular/core';
import {UntypedFormArray, FormGroupDirective} from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';
import {TRANSLATION_BASE} from 'app/constants/injection-tokens';
import {Observable} from 'rxjs';

import {MposFormDirective} from 'app/common/mpos-forms/directives/mpos-form.directive';
import {MposFormElement} from 'app/common/mpos-forms/shared/mpos-form-element';
import {Store} from '@ngxs/store';
import {RoleService} from 'app/blocks/service/api/role.service';

export const formElementProvider: any = {
    provide: MposFormElement,
    useExisting: forwardRef(() => MposDualListComponent)
};

@Component({
    selector: 'mpos-dual-list',
    template: `
        <div [formGroup]="thisFormGroup" fxFlex style="width: 100%;" *ngIf="this.showField">
            <label class="mat-body-2">{{ fieldLabel$ | async }}</label>
            <dual-list
                [source]="options$ | async"
                [(destination)]="selectedOptions"
                [display]="displayAttribute"
                key="id"
                (destinationChange)="onSelectionChange()"
                [disabled]="readonly">
            </dual-list>
        </div>
    `,
    // <app-custom-listbox [source]="(options$ | async)" [(destination)]="selectedOptions" height="350px"
    // (selectChange)="onSelectionChange()" sourceName="Available PriceLists" targetName="Selected PriceLists">
    // </app-custom-listbox>
    styleUrls: ['./mpos-dual-list.component.scss'],
    providers: [formElementProvider],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class MposDualListComponent extends MposFormElement implements OnInit {
    @Input() field: string;
    @Input() displayAttribute = 'id';
    @Input() options$: Observable<any>;
    @Input() selectedOptions: any;
    @Input() roleSettingSuffix = '';
    @Input() showField = true;
    @Input() readonly = false;

    thisArray: UntypedFormArray;

    constructor(
        @Inject(TRANSLATION_BASE) translationBase,
        translateService: TranslateService,
        fgDirective: FormGroupDirective,
        @Optional() mposForm: MposFormDirective,
        private cdRef: ChangeDetectorRef,
        protected roleService: RoleService
    ) {
        super(translationBase, translateService, fgDirective, roleService, mposForm);
    }
    ngOnInit(): void {
        super.ngOnInit();
        this.thisArray = this._thisControl as UntypedFormArray;
        this.selectedOptions = this.thisArray.value || [];
        this.thisArray.valueChanges.subscribe((change) => {
            this.selectedOptions = this.thisArray.value || [];
            this.cdRef.detectChanges();
        });
    }

    getFieldName(): string {
        return this.field;
    }

    defaultCompareFn = (c1, c2) => {
        return c1 && c2 ? c1.id === c2.id : c1 === c2;
    };

    onSelectionChange(): void {
        this.thisFormGroup.patchValue({[this.field]: this.selectedOptions});
    }
}
