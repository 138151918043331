import {BaseEntity, IBaseEntity} from 'app/blocks/model/base-entity.model';
import {MediaAssetType} from 'app/blocks/enum/media-asset-type.enum';

export interface IVideo extends IBaseEntity {
    id?: number;
    name?: string;
    originalFileName: string;
    path?: string;
    multipartFile?: File;
    sequence?: number;
    mediaAssetType?: MediaAssetType;
    type?: string;
}

export class Video extends BaseEntity implements IVideo {
    public id?: number;
    public name?: string;
    public originalFileName: string;
    public path?: string;
    public multipartFile?: File;
    public sequence?: number;
    public mediaAssetType?: MediaAssetType;
    public type?: string;

    constructor(video?) {
        super();
        if (video) {
            this.id = video.id;
            this.name = video.name;
            this.originalFileName = video.originalFileName;
            this.path = video.path;
            this.sequence = video.sequence;
            this.mediaAssetType = video.mediaAssetType;
            this.type = video.type;
        } else {
        }
    }

    get discriminator(): any {
        return this.name;
    }

    clone(): Video {
        return new Video(this);
    }
}
