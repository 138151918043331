import {Pipe, PipeTransform} from '@angular/core';

@Pipe({name: 'userEmployee'})
export class UserEmployeePipe implements PipeTransform {
    transform(value: any, args?: string[]): any {
        if (!value || !value.empDbId) {
            return value;
        }
        return {id: value.empDbId};
    }
}
