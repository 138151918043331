import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Inject,
    Input,
    OnInit,
    Output,
    Renderer2,
    ViewEncapsulation,
    Optional,
    ViewChild
} from '@angular/core';
import {FormGroupDirective} from '@angular/forms';
import {ErrorStateMatcher} from '@angular/material/core';
import {faInfoCircle} from '@fortawesome/free-solid-svg-icons';
import {TranslateService} from '@ngx-translate/core';
import {TRANSLATION_BASE} from 'app/constants/injection-tokens';

import {MposFormElement} from 'app/common/mpos-forms/shared/mpos-form-element';
import {MposFormDirective} from 'app/common/mpos-forms/directives/mpos-form.directive';
import {Symbol} from 'app/blocks/model/symbol.model';
import {forwardRef, HostBinding, AfterContentChecked, ElementRef} from '@angular/core';
import {debounce, round} from 'lodash';
import {LogService} from 'app/blocks/service/log.service';
import {RoleService} from 'app/blocks/service/api/role.service';

export const formElementProvider: any = {
    provide: MposFormElement,
    useExisting: forwardRef(() => MposInputComponent)
};

@Component({
    selector: 'mpos-input',
    template: `
        <nz-form-item [formGroup]="thisFormGroup" [ngStyle]="{'justify-content': getPosition()}" *ngIf="showField">
            <div [ngStyle]="{width: width}">
                <nz-form-label *ngIf="!suppressLabel" [nzFor]="fieldLabel$ | async" [nzRequired]="fieldIsRequired" [nzNoColon]="true">
                    <span>
                        {{ fieldLabel$ | async }}
                        <fa-icon *ngIf="fieldHint$ | async as hint" [icon]="faInfoIcon" size="xs" nz-tooltip [nzTooltipTitle]="hint"></fa-icon>
                    </span>
                </nz-form-label>
                <span *ngIf="!showOnlyLabel">
                    <nz-form-control [nzErrorTip]="suppressError ? null : (validationError$ | async)">
                        <nz-input-group [nzSuffix]="suffix" [nzPrefix]="prefix" nzAddOnBefore="{{ addOnBefore }}">
                            <input
                                nz-input
                                #inputElement
                                id="{{ 'mpos-input-' + field + idSuffix }}"
                                [ngStyle]="{width: width, 'max-width': width}"
                                name="{{ field }}"
                                formControlName="{{ field }}"
                                type="{{ type }}"
                                [disabled]="nzDisabled"
                                [required]="fieldIsRequired"
                                [readonly]="!!readonly"
                                [placeholder]="placeholder ?? ''"
                                [nzStepperless]="type.toLowerCase() !== 'number'"
                                (keydown.Tab)="onTab($event); onTabPress($event)"
                                (keydown.Enter)="onEnter($event)"
                                (input)="onInput($event)" />
                        </nz-input-group>
                    </nz-form-control>
                </span>
            </div>
        </nz-form-item>
    `,
    styleUrls: ['./mpos-input.component.scss'],
    encapsulation: ViewEncapsulation.None,
    providers: [formElementProvider],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class MposInputComponent extends MposFormElement implements OnInit {
    @Input() set labelOverride(label: string) {
        this._labelOverride = label;
        this.setLabel(label);
    }

    constructor(
        @Inject(TRANSLATION_BASE) translationBase,
        private renderer: Renderer2,
        fgDirective: FormGroupDirective,
        @Optional() mposForm: MposFormDirective,
        translateService: TranslateService,
        private _logService: LogService,
        protected roleService: RoleService
    ) {
        super(translationBase, translateService, fgDirective, roleService, mposForm);
    }
    @ViewChild('inputElement', {static: true})
    inputElement: ElementRef<HTMLElement>;

    faInfoIcon = faInfoCircle;
    prefix = null;
    suffix = null;
    _labelOverride: string | null = null;
    numericValue: number | null = null;

    @Input() @HostBinding('class.flex-grow') grow = true;
    @Input() @HostBinding('style.min-width') minWidth: string;
    @Input() @HostBinding('style.width') outerWidth: string;
    @Input() width = '100%';
    @Input() align: 'start' | 'center' | 'end' = 'start';
    @Input() focus = false;
    @Input() min: number | null;
    @Input() max: number | null;
    @Input() idSuffix = '';
    @Input() placeholder?: string = '';

    @Input() field: string | null;
    @Input() suppressLabel = false;
    @Input() suppressError = false;
    @Input() integersOnly = false;
    @Input() type = 'text';
    @Input() errorVariantMap: {[errorKey: string]: string};
    @Input() readonly = false;
    @Input() nzDisabled = false;
    @Input() roleSettingSuffix = '';
    @Input() showField = true;
    @Input() customErrorStateMatcher: ErrorStateMatcher;
    @Input() symbol: Symbol = null;
    @Input() showPercentageSymbol = false;

    @Output() enterPress = new EventEmitter<any>();
    @Output() tabPress = new EventEmitter<any>();

    // tslint:disable-next-line:no-output-native
    @Output() input = new EventEmitter<any>();
    @Input() addOnBefore = '';
    @Input() showOnlyLabel = false;
    @Input() onTab: (event) => any = (event) => {};
    @Input() enableTracking = false;

    ngOnInit(): void {
        super.ngOnInit();
        if (this.symbol) {
            if (this.symbol.isPrefix()) {
                this.prefix = this.symbol.getCode();
            } else {
                this.suffix = this.symbol.getCode();
            }
        } else if (this.showPercentageSymbol) {
            this.suffix = '%';
        }
        if (this.focus) {
            this.focusNow();
        }
    }

    focusNow(): void {
        setTimeout(() => this.inputElement?.nativeElement?.focus(), 0);
    }

    getFieldName(): string {
        return this.field;
    }

    getLabelOverride(): any {
        return this._labelOverride;
    }

    getPosition(): string {
        if (this.align !== 'center') {
            return 'flex-' + this.align;
        }
        return this.align;
    }

    private debouncedLog = debounce((value) => {
        this._logService.log('Input Change', {
            field: this._fieldPath,
            value: value
        });
    }, 500);

    onInput($event: any): any {
        if (this.integersOnly) {
            $event.target.value = round($event.target.value);
        }

        setTimeout(() => {
            this.input.emit($event);
        }, 500);
        if (this.enableTracking) {
            this.debouncedLog($event.target.value);
        }
    }

    onEnter($event: any): void {
        this.enterPress.emit($event);
    }

    onTabPress($event: any): void {
        this.tabPress.emit($event);
    }

    // get runChangeDetection(): string {
    //     console.log(`Change detection cycle: ${++this.changecounter} ${this.field}`);
    //     return '';
    // }
}
