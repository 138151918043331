import {Component, Inject, Input, OnInit, Optional} from '@angular/core';
import {FormGroupDirective} from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';
import {MposFormElement} from 'app/common/mpos-forms/shared/mpos-form-element';
import {TRANSLATION_BASE} from 'app/constants/injection-tokens';
import {MposFormDirective} from 'app/common/mpos-forms/directives/mpos-form.directive';
import {FALLBACK_IMAGE_B64} from 'app/app.constants';
import {DomSanitizer} from '@angular/platform-browser';
import {Image} from 'app/blocks/model/image.model';
import {ImageService} from 'app/blocks/service/api/image.service';
import {debounceTime, takeUntil} from 'rxjs';
import {ImageVariant} from 'app/blocks/enum/image.variant.enum';
import {NzImageService} from 'ng-zorro-antd/image';
import {Store} from '@ngxs/store';
import {RoleService} from 'app/blocks/service/api/role.service';

@Component({
    selector: 'di-image',
    providers: [NzImageService],
    template: `<div class="mpos-form-section" style="padding: 5px">
        @if (!suppressLabel) {
            <nz-form-label [nzFor]="fieldLabel$ | async" [nzRequired]="fieldIsRequired" [nzNoColon]="true" *ngIf="this.readonly">
                <span>
                    {{ fieldLabel$ | async }}
                </span>
            </nz-form-label>
        }
        <nz-spin [nzSpinning]="loadingImage">
            <img
                [height]="height"
                [width]="width"
                class="preview-image"
                [src]="_domSanitizationService.bypassSecurityTrustUrl(imgSrc)"
                alt="Catalogue Cover Image"
                (click)="handleImageClick()" />
        </nz-spin>
        @if (!readonly) {
            <button nz-button nzType="dashed" (click)="uploader.click()" nzSize="small"><span nz-icon nzType="upload"></span> upload</button>
            <input hidden #uploader type="file" id="file" size="20" (change)="handleUploadImage($event)" />
        }
    </div>`
})
export class DiImageComponent extends MposFormElement implements OnInit {
    @Input({required: true}) field: string;
    @Input() uploadImage: (name: string, file: File) => Promise<Image>;
    @Input() height = 200;
    @Input() width = 200;
    @Input() readonly = false;
    @Input() suppressLabel = false;
    @Input() variant = ImageVariant.THUMBNAIL;

    @Input() roleSettingSuffix = '';
    @Input() showField = true;

    imgSrc: string = FALLBACK_IMAGE_B64;
    fullImageSrc?: string;
    loadingImage = false;

    constructor(
        @Inject(TRANSLATION_BASE) translationBase: any,
        fgDirective: FormGroupDirective,
        @Optional() mposForm: MposFormDirective,
        translateService: TranslateService,
        public _domSanitizationService: DomSanitizer,
        public _imageService: ImageService,
        private nzImageService: NzImageService,
        protected roleService: RoleService
    ) {
        super(translationBase, translateService, fgDirective, roleService, mposForm);
    }

    ngOnInit(): void {
        console.log('ngOnInit');
        super.ngOnInit();
        this.handleImageValueChange(this._thisControl.value);
        this._thisControl.valueChanges
            .pipe(takeUntil(this._unsubscribeAll), debounceTime(100))
            .subscribe((value: Image) => this.handleImageValueChange(value));
    }

    getFieldName(): string {
        return this.field;
    }

    private handleImageValueChange(value: Image) {
        if (value == null) {
            return;
        }

        this.loadingImage = true;
        this.fullImageSrc = undefined;
        this._imageService.getImageDeliveryUrl(value.id, this.variant).subscribe({
            next: (url) => {
                this.imgSrc = url;
                this.loadingImage = false;
            },
            error: (error) => {
                console.error('Error getting image url', error);
                this.loadingImage = false;
            },
            complete: () => {
                this.loadingImage = false;
            }
        });
    }

    showFullImagePreview(): void {
        if (this.fullImageSrc == null) {
            return;
        }

        this.nzImageService.preview([
            {
                src: this.fullImageSrc,
                alt: 'Full Image'
            }
        ]);
    }

    handleImageClick(): void {
        const image = this._thisControl.value as Image;
        if (image == null) {
            return;
        }

        if (this.fullImageSrc == null) {
            this._imageService.getImageDeliveryUrl(image.id, ImageVariant.FULL).subscribe({
                next: (url) => {
                    this.fullImageSrc = url;
                    this.showFullImagePreview();
                },
                error: (error) => {
                    console.error('Error getting image url', error);
                }
            });
        } else {
            this.showFullImagePreview();
        }
    }

    handleUploadImage(event: Event) {
        const file = (event.target as HTMLInputElement).files[0];
        if (file) {
            if (!this.uploadImage) {
                throw new Error('uploadImage function not provided');
            }
            this.loadingImage = true;
            this.uploadImage(file.name, file)
                .then((image) => {
                    this._thisControl.setValue(image);
                })
                .finally(() => {
                    this.loadingImage = false;
                });
        } else {
            console.log('no file selected');
        }
    }
}
