import {AfterContentInit, ContentChildren, Directive, ElementRef, QueryList} from '@angular/core';

import {MposFormElement} from 'app/common/mpos-forms/shared/mpos-form-element';

@Directive({
    selector: '[diUserInputGroup]',
    exportAs: 'diUserInputGroup'
})
export class DiUserInputGroupDirective implements AfterContentInit {
    @ContentChildren(MposFormElement, {descendants: true})
    formElements: QueryList<MposFormElement>;

    constructor(public elRef: ElementRef) {}

    ngAfterContentInit(): void {
        // this.formElements.forEach(formElements => console.log(formElements));
    }

    getValidationErrors(): string[] {
        const validationErrors = [];
        this.formElements.forEach((formElement) => {
            const error = formElement.getValidatorError();
            if (error !== null) {
                validationErrors.push(error);
            }
        });
        return validationErrors;
    }
}
