import {Pipe, PipeTransform} from '@angular/core';
import {ICurrency} from 'app/blocks/model/currency.model';
import {DisplayUtilsService} from 'app/common/display-utils-service';
import {DECIMAL_PLACES} from 'app/app.constants';
import {formatNumber} from '@angular/common';

@Pipe({name: 'mposCurrency'})
export class CurrencyPipe implements PipeTransform {
    constructor(private _displayUtilsService: DisplayUtilsService) {}

    /**
     * Transform
     *
     * @param {number} amount
     * @param {ICurrency} currency
     * @returns {string}
     */
    transform(amount: number, currency: ICurrency): string {
        let result = '';
        if (amount != null && currency) {
            const decodedCurrencySymbol = this._displayUtilsService.decodeHTMLEntity(currency.htmlCode);
            if (currency.prefix) {
                result = `${decodedCurrencySymbol} ${formatNumber(amount, 'en-US', '1.2')}`;
            } else {
                result = `${formatNumber(amount, 'en-US', '1.2')} ${decodedCurrencySymbol}`;
            }
        } else if (amount != null) {
            result = '' + amount;
        }
        return result;
    }
}
