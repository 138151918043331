import {Injectable} from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class DisplayUtilsService {
    constructor() {}

    decodeHTMLEntity(str: string): string {
        if (!str) {
            return str;
        }

        let result = '';
        const tempArray = str.split(';');

        for (const temp of tempArray) {
            if (!temp) {
                continue;
            }

            result += temp.replace(/&#(\d+)/g, (match, dec): string => {
                return String.fromCharCode(dec);
            });
        }

        return result;
    }

    convertToHoursAndMins(mins: number, symbolForZeroMins = ' - '): string {
        let hhmm = '';
        if (mins === 0 && symbolForZeroMins) {
            hhmm = symbolForZeroMins;
        } else if (mins != null) {
            const hh = Math.floor(mins / 60);
            const mm = mins % 60;
            if (hh !== 0) {
                hhmm += hh + ' h';
                if (mm !== 0) {
                    hhmm += ' ';
                }
            }
            if (mm !== 0) {
                hhmm += mm + ' m';
            }
        }
        return hhmm;
    }

    getWithFixedDecimals(value: number | string, decimalPlaces: any = 2): number | string {
        if (typeof value === 'number' && !Number.isInteger(value)) {
            return value.toFixed(decimalPlaces);
        }
        return value;
    }

    hexToRGBA(hex: string, alpha: number): string {
        let r: string | number = parseInt(hex?.slice(1, 3), 16),
            g: string | number = parseInt(hex?.slice(3, 5), 16),
            b: string | number = parseInt(hex?.slice(5, 7), 16);

        if (alpha) {
            return `rgba(${r}, ${g}, ${b}, ${alpha})`;
        } else {
            return `rgb(${r}, ${g}, ${b})`;
        }
    }
}
