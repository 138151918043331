import {Component, Inject, Input, OnInit, Optional} from '@angular/core';
import {MposFormElement} from 'app/common/mpos-forms/shared/mpos-form-element';
import {MediaAssetType} from 'app/blocks/enum/media-asset-type.enum';
import {TRANSLATION_BASE} from 'app/constants/injection-tokens';
import {MposFormDirective} from 'app/common/mpos-forms/directives/mpos-form.directive';
import {FormGroupDirective} from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';
import {Store} from '@ngxs/store';
import {RoleService} from 'app/blocks/service/api/role.service';

@Component({
    selector: 'di-media-asset',
    template: `
        <!-- Conditionally render different elements based on media type -->
        <ng-container [ngSwitch]="mediaAssetType">
            <di-image [field]="field" *ngSwitchCase="'IMAGE'" [readonly]="readonly" [suppressLabel]="suppressLabel"></di-image>
            <di-video [field]="field" *ngSwitchCase="'VIDEO'" [readonly]="readonly" [suppressLabel]="suppressLabel"></di-video>
            <!-- Example for 3D Model, assuming you're using a <model-viewer> or similar -->
            <!-- <model-viewer *ngSwitchCase="'MODEL_3D'" [src]="entity.path" alt="3D Model" auto-rotate camera-controls></model-viewer> -->
            <!-- Add more cases as needed for other media types -->
            <span *ngSwitchDefault>Unsupported media type</span>
        </ng-container>
    `
})
export class DiMediaAssetComponent extends MposFormElement implements OnInit {
    @Input({required: true}) field: string;
    @Input({required: true}) mediaAssetType: MediaAssetType;
    @Input() height = 200;
    @Input() width = 200;
    @Input() readonly = false;
    @Input() suppressLabel = false;

    constructor(
        @Inject(TRANSLATION_BASE) translationBase: any,
        fgDirective: FormGroupDirective,
        @Optional() mposForm: MposFormDirective,
        translateService: TranslateService,
        protected roleService: RoleService
    ) {
        super(translationBase, translateService, fgDirective, roleService, mposForm);
    }

    getFieldName(): string {
        return this.field;
    }

    ngOnInit(): void {
        super.ngOnInit();
    }
}
