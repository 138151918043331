import {takeUntil} from 'rxjs/operators';
import {ChangeDetectionStrategy, Component, EventEmitter, Inject, Input, OnInit, Output, forwardRef, Optional, HostBinding} from '@angular/core';
import {FormGroupDirective} from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';
import {TRANSLATION_BASE} from 'app/constants/injection-tokens';
import * as fns from 'date-fns';
import {MposFormElement} from 'app/common/mpos-forms/shared/mpos-form-element';
import {MposFormDirective} from 'app/common/mpos-forms/directives/mpos-form.directive';
import {NzI18nService, en_GB, fr_FR} from 'ng-zorro-antd/i18n';
import {faInfoCircle} from '@fortawesome/free-solid-svg-icons';
import {LogService} from 'app/blocks/service/log.service';
import {debounce} from 'lodash';
import {Store} from '@ngxs/store';
import {RoleService} from 'app/blocks/service/api/role.service';

export const formElementProvider: any = {
    provide: MposFormElement,
    useExisting: forwardRef(() => MposDateComponent)
};

@Component({
    selector: 'mpos-date',
    template: `
        <nz-form-item [formGroup]="thisFormGroup" [ngStyle]="{'justify-content': getPosition()}" *ngIf="this.showField">
            <div [ngStyle]="{width: width}">
                <nz-form-label *ngIf="!suppressLabel" [nzFor]="fieldLabel$ | async" [nzRequired]="fieldIsRequired" [nzNoColon]="true">
                    <span>
                        {{ fieldLabel$ | async }}
                        <fa-icon *ngIf="fieldHint$ | async as hint" [icon]="faInfoIcon" size="xs" nz-tooltip [nzTooltipTitle]="hint"></fa-icon>
                    </span>
                </nz-form-label>
                <nz-form-control [nzErrorTip]="suppressError ? null : (validationError$ | async)">
                    <nz-date-picker
                        id="{{ 'mpos-date-' + field + idSuffix }}"
                        [nzDisabled]="!!readonly"
                        [ngStyle]="{width: width, 'max-width': width}"
                        [nzAutoFocus]="focus"
                        nzFormat="dd/MM/yyyy"
                        formControlName="{{ field }}"
                        [nzDisabledDate]="disabledDate"
                        (ngModelChange)="dateChanged($event)">
                    </nz-date-picker>
                </nz-form-control>
            </div>
        </nz-form-item>
    `,
    styleUrls: [`./mpos-date.component.scss`],
    providers: [formElementProvider],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class MposDateComponent extends MposFormElement implements OnInit {
    @Input() @HostBinding('class.flex-grow') grow = true;
    @Input() @HostBinding('style.width') outerWidth: string;
    @Input() width = '100%';
    @Input() align: 'start' | 'center' | 'end' = 'start';
    @Input() focus = false;

    @Input() field: string;
    @Input() idSuffix = '';
    @Input() startDate: any;
    @Input() suppressLabel = false;
    @Input() suppressError = false;
    @Input() minDate: any;
    @Input() maxDate: any;
    @Input() readonly = false;
    @Input() roleSettingSuffix = '';
    @Input() showField = true;
    // @Input() customErrorStateMatcher: ErrorStateMatcher;
    @Input() set labelOverride(label: string) {
        this._labelOverride = label;
        this.setLabel(label);
    }

    _labelOverride: string | null = null;

    @Output() dateChange: EventEmitter<any> = new EventEmitter<any>();

    faInfoIcon = faInfoCircle;
    @Input() enableTracking = false;

    constructor(
        @Inject(TRANSLATION_BASE) translationBase,
        translateService: TranslateService,
        fgDirective: FormGroupDirective,
        @Optional() mposForm: MposFormDirective,
        private i18n: NzI18nService,
        private _logService: LogService,
        protected roleService: RoleService
    ) {
        super(translationBase, translateService, fgDirective, roleService, mposForm);

        translateService.onLangChange.pipe(takeUntil(this._unsubscribeAll)).subscribe((lang) => {
            // this.i18n.setLocale(this.isEnglish ? en_US : fr_FR);
        });
    }

    getFieldName(): string {
        return this.field;
    }

    getPosition(): string {
        if (this.align !== 'center') {
            return 'flex-' + this.align;
        }
        return this.align;
    }

    private debouncedLog = debounce((value) => {
        this._logService.log('Date Change', {
            field: this._fieldPath,
            value: value
        });
    }, 500);

    dateChanged(newDate: any): void {
        this.dateChange.emit(newDate);
        if (this.enableTracking) {
            this.debouncedLog(newDate);
        }
    }

    disabledDate = (currentSelection: Date): boolean => {
        let disabled = false;
        if (this.minDate) {
            disabled = fns.differenceInCalendarDays(this.minDate, currentSelection) > 0;
        }
        if (!disabled && this.maxDate) {
            disabled = fns.differenceInCalendarDays(currentSelection, this.maxDate) > 0;
        }
        return disabled;
    };
    getLabelOverride(): any {
        return this._labelOverride;
    }
}
