import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    forwardRef,
    HostBinding,
    HostListener,
    Inject,
    Input,
    OnInit,
    Optional,
    Output,
    ViewChild,
    ViewEncapsulation
} from '@angular/core';
import {FormGroupDirective} from '@angular/forms';
import {faInfoCircle} from '@fortawesome/free-solid-svg-icons';
import {TranslateService} from '@ngx-translate/core';
import {TRANSLATION_BASE} from 'app/constants/injection-tokens';
import {NzSelectComponent} from 'ng-zorro-antd/select';
import {concat, Observable, of, Subject} from 'rxjs';
import {catchError, debounceTime, distinctUntilChanged, switchMap, tap} from 'rxjs/operators';
import {MposFormElement} from 'app/common/mpos-forms/shared/mpos-form-element';
import {MposFormDirective} from 'app/common/mpos-forms/directives/mpos-form.directive';
import {LogService} from 'app/blocks/service/log.service';
import {debounce} from 'lodash';
import {Store} from '@ngxs/store';
import {RoleService} from 'app/blocks/service/api/role.service';

export const formElementProvider: any = {
    provide: MposFormElement,
    useExisting: forwardRef(() => MposSearchSelectComponent)
};
/**
 * [nzFilterOption]="nzFilterOption" This allows us to filter the options separately on the UI.
 * Add if required later
 */
@Component({
    selector: 'mpos-search-select',
    template: `
        <nz-form-item [formGroup]="thisFormGroup" [ngStyle]="{'justify-content': getPosition()}" *ngIf="this.showField">
            <div [ngStyle]="{width: width}">
                <span *ngIf="warning"> {{ warning }} </span>
                <nz-form-label *ngIf="!suppressLabel" [nzFor]="fieldLabel$ | async" [nzRequired]="fieldIsRequired" [nzNoColon]="true">
                    <span>
                        {{ fieldLabel$ | async }}
                        <fa-icon *ngIf="fieldHint$ | async as hint" [icon]="faInfoIcon" size="xs" nz-tooltip [nzTooltipTitle]="hint"></fa-icon>
                    </span>
                </nz-form-label>
                <nz-form-control [nzErrorTip]="suppressError ? null : (validationError$ | async)">
                    <nz-select
                        id="{{ 'mpos-search-select-' + field + idSuffix }}"
                        [nzDisabled]="!!readonly"
                        [ngStyle]="{
                            width: width,
                            'max-width': width,
                            color: color
                        }"
                        [nzAutoFocus]="focus"
                        nzShowSearch
                        nzServerSearch
                        [nzShowArrow]="false"
                        (nzOnSearch)="search($event)"
                        (ngModelChange)="onChange($event)"
                        formControlName="{{ field }}"
                        [compareWith]="compareFn"
                        [nzAllowClear]="true"
                        (nzOpenChange)="registerOpen($event)"
                        nzPlaceHolder="Search"
                        nzMode="{{ mode }}">
                        <ng-container *ngFor="let option of options$ | async">
                            <nz-option *ngIf="!loading" [nzValue]="option" [nzLabel]="getLabel(option)"></nz-option>
                        </ng-container>
                        <nz-option *ngIf="loading" nzDisabled nzCustomContent>
                            <i nz-icon nzType="loading" class="loading-icon"></i>
                            Loading Data...
                        </nz-option>
                    </nz-select>
                </nz-form-control>
            </div>
        </nz-form-item>
    `,
    styleUrls: ['./mpos-search-select.component.scss'],
    providers: [formElementProvider],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class MposSearchSelectComponent extends MposFormElement implements OnInit {
    @ViewChild(NzSelectComponent, {static: true})
    nzSelectElement: NzSelectComponent;

    @Input() @HostBinding('class.flex-grow') grow = true;
    @Input() @HostBinding('style.width') outerWidth: string;
    @Input() @HostBinding('style.min-width') minWidth: string;
    @Input() width = '100%';
    @Input() color = 'black';
    @Input() align: 'start' | 'center' | 'end' = 'start';
    @Input() focus = false;
    @Input() idSuffix = '';

    @Input() field: string;
    @Input() displayAttribute = null;
    @Input() options$: Observable<any>;
    @Input() suppressLabel = false;
    @Input() suppressError = false;
    @Input() customSearchFn: (term: string) => Observable<any[]>;
    @Input() customDisplayFn: (item: any) => string = null;
    @Input() compareFn: (item1, item2) => boolean;
    @Input() readonly = false;
    @Input() mode = 'default';
    @Input() enableTracking = false;
    @Input() roleSettingSuffix = '';
    @Input() showField = true;
    @Input() warning;

    @Output() selectionChange = new EventEmitter<any>();
    @Output() tabPress = new EventEmitter<any>();

    searchHappened = false;
    openHappened = false;
    faInfoIcon = faInfoCircle;
    loading = false;
    userInput$ = new Subject<string>();

    @HostListener('keydown.Tab', ['$event'])
    onTabPress($event: KeyboardEvent): void {
        this.tabPress.emit($event);
    }

    @HostListener('window:keydown', ['$event'])
    onControlCPress($event: KeyboardEvent): void {
        if (($event.ctrlKey || $event.metaKey) && $event.code === 'KeyC' && this.nzSelectElement.nzOpen) {
            navigator.clipboard.writeText(this.getLabel(this.nzSelectElement.activatedValue));
        }
    }

    constructor(
        @Inject(TRANSLATION_BASE) translationBase,
        translateService: TranslateService,
        fgDirective: FormGroupDirective,
        private _logService: LogService,
        @Optional() mposForm: MposFormDirective,
        protected roleService: RoleService
    ) {
        super(translationBase, translateService, fgDirective, roleService, mposForm);
    }

    focusNow(): void {
        if (this.nzSelectElement) {
            this.nzSelectElement.focus();
        }
    }

    registerOpen(opened: Event): void {
        this.openHappened = true;
        if (!opened) {
            // i.e. dropdown was closed
            setTimeout(() => this.focusNow(), 0);
        }
    }

    getFieldName(): string {
        return this.field;
    }

    getPosition(): string {
        if (this.align !== 'center') {
            return 'flex-' + this.align;
        }
        return this.align;
    }

    ngOnInit(): void {
        super.ngOnInit();
        if (!this.compareFn) {
            this.compareFn = this.defaultCompareFn;
        }
        // this.initOptionSearch();
    }

    getLabel(option: any): string {
        if (option) {
            if (this.customDisplayFn) {
                return this.customDisplayFn(option);
            } else if (this.displayAttribute) {
                return option[this.displayAttribute];
            }
        }
        return null;
    }

    defaultCompareFn = (c1, c2) => {
        return c1 && c2 ? c1.id === c2.id : c1 === c2;
    };

    search(value: string): void {
        if (value === '') {
            return;
        }

        if (!this.searchHappened) {
            this.initOptionSearch();
        }
        if (value && value.length >= 2) {
            this.loading = true;
            setTimeout(() => this.userInput$.next(value), 10);
        }
    }

    private initOptionSearch(): void {
        if (this.customSearchFn) {
            this.options$ = concat(
                of([]), // default items
                this.userInput$.pipe(
                    debounceTime(200),
                    distinctUntilChanged(),
                    switchMap((term) =>
                        this.customSearchFn(term).pipe(
                            catchError(() => of([])), // empty list on error
                            tap(() => {
                                this.loading = false;
                            })
                        )
                    )
                )
            );
            this.searchHappened = true;
        }
    }

    onChange(selectedItem: any): void {
        // this.selectionChange.emit(selectedItem);
    }

    private debouncedLog = debounce((value) => {
        this._logService.log('Search Select Change', {
            field: this._fieldPath,
            value: value
        });
    }, 500);

    onValueChange(controlValue): void {
        this.selectionChange.emit(controlValue);
        if (this.enableTracking) {
            this.debouncedLog(controlValue);
        }
    }
}
